import React from 'react';
import './App.css';

import HomePage from 'pages/HomePage';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import LanderPage from 'pages/LanderPage'
import TermsOfServicePage from 'pages/TermsOfServicePage';


export const isPartnerSite = () => {
  let subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0];
  return subdomain !== "finstead" && subdomain !== 'localhost:3000';
}


function App() {


  if(isPartnerSite()){
    return (
      <Router>
          <Switch>
            <Route path="/" exact>
              <div>
                <LanderPage />
                <Footer />
              </div>
            </Route>
            <Route path="/privacy-policy" exact>
              <div>
                <PrivacyPolicyPage />
                <Footer />
              </div>
            </Route>
            <Route path="/terms-of-service" exact>
              <div>
                <TermsOfServicePage />
                <Footer />
              </div>
            </Route>
            <Route path="/contact" exact>
              <div>
                <LanderPage />
                <Footer />
              </div>
            </Route>
          </Switch>
        </Router>
      
    )
  }

  return (
    <div className="App">
      <div>
        

        <Router>
          <Switch>
            <Route path="/" exact>
              <Header />
              <HomePage />
            </Route>
            <Route path="/privacy-policy" exact>
              <PrivacyPolicyPage />
            </Route>
            <Route path="/terms-of-service" exact>
              <TermsOfServicePage />
            </Route>
            <Route path="/contact" exact>
              <LanderPage />
            </Route>
          </Switch>
        </Router>

        <Footer />
      </div>
    </div>
  )
}

export default App;

import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from 'react-helmet'
import Header from 'components/Header';
import { isPartnerSite } from 'App';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles.css';

const LanderPage = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errored, setErrored] = useState(false);
    const [configuration, setConfiguration] = useState({});
  
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [value, setValue] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [loanType, setLoanType] = useState('business loan');
    const [title, setTitle] = useState()
  
    useEffect(() => {
      if(isPartnerSite()){
        axios.get("https://prod-api.finstead.com.au/partners/lead-page-configuration/").then(response => {
          setConfiguration(response.data);
          setTitle(gettitle(partner))
        })
      }
    }, [])
  
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  
    const gettitle = (_partner) => {
  
      if(Object.keys(configuration).indexOf("title") != -1) {
        return configuration.title
      }
  
      if(_partner.indexOf("and") !== -1){
        return _partner.split("and").map(x => capitalize(x)).join(" And ")
      }
      return capitalize(_partner)
    }
  
    const [partner, _] = useState(/:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0]);
  
    const submit = (e) => {
      e.preventDefault();
  
      setSubmitting(true);
      axios.post("https://prod-api.finstead.com.au/partners/lead-page-lead/", {
        "asset_type": loanType,
        "asset_value": value,
        "first_name": firstName,
        "last_name": lastName,
        "email_address": emailAddress,
        "contact_number": contactNumber
      })
      .then(
        response => {
          setSubmitted(true);
          setSubmitting(false);
        }
      ).catch(error => {
          setErrored(true);
          setSubmitting(false);
      })
    }
    
  
    return (
      <div>
        <Header title={title} partner_config={configuration && Object.keys(configuration).length !== 0 ? configuration : undefined} />
        
        <div>
          
          {/* /Header */} 
          {/* Intro */}
          <section id="intro-2" className="section-padding">
            <div className="container pt-4">
              <div className="row">
                <div className="col-md-7">
                  <div className="intro-text white-text z-index">
                    <h1>Your <span className='partners-highlight'>Partners</span> in Business Finance</h1>
                    <ul>
                      <li><i className="fa fa-check" /> One dedicated specialist from start to finish</li>
                      <li><i className="fa fa-check" /> Tailored finance solutions</li>
                      <li><i className="fa fa-check" /> Market leading rates</li>
                      <li><i className="fa fa-check" /> A comprehensive range of finance products</li>
                      <li><i className="fa fa-check" /> Ongoing finance support</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-wrap z-index">
                    <div className="form-head" style={{color: "white"}}>
                      {errored ? <h4>Uh oh! There was an error.</h4> : submitted ? <h4>Success!</h4> : submitting ? <h4>Sending your request...</h4> : <h4>Let us call you!</h4>}
                    </div>
  
                    {
                      submitting ?  <div className='text-center'><ClipLoader loading={true} color={"white"}/></div> :
                      errored ? <div className='text-center'>
                        <i className='fa fa-times-circle' style={{color:"white", fontSize: "25px", marginBottom: "15px"}}></i>
                        <p className='lead' style={{color: "white"}}>Something went wrong when submitting your referral, please refresh the page and try again.</p></div> :
                      submitted ? <div className='text-center'>
                        
                        <i className='fa fa-check-circle' style={{color:"white", fontSize: "25px", marginBottom: "15px"}}></i>
                        <p className='lead text-center' style={{color: "white"}}>You have successfully submitted a referral to Finstead.</p>
                        <p className='lead' style={{color :"white"}}>{title ? `A Finstead broker dedicated to ${title} clients will be in touch with you shortly` : 'One of our brokers will contact you during the next business day'}</p>
                      </div> :
                      <form onSubmit={submit}>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="First Name" required value={firstName} onChange={e => setFirstName(e.target.value)}
                              disabled={submitting}/>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Last Name" required value={lastName} onChange={e => setLastName(e.target.value)} disabled={submitting}/>
                            </div>
                          </div>
                        </div>
  
                        
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Mobile Phone" required value={contactNumber} onChange={e => setContactNumber(e.target.value)} disabled={submitting}/>
                        </div>
                        <div className="form-group">
                          <input type="email" className="form-control" placeholder="Email Address" required value={emailAddress} onChange={e => setEmailAddress(e.target.value)} disabled={submitting}/>
                        </div>
                        <div className="form-group">
                          <input type="number" className="form-control" placeholder="$ How much would you like?" required value={value} onChange={e => setValue(e.target.value)} disabled={submitting}/>
                        </div>
                        
                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="radio">
                                <input type="radio" name="loan-radio" defaultValue="radio" id="loan-radio_2" value="business loan" onChange={(e)=>{
                                  setLoanType("business loan")
                                }} checked={loanType==="business loan"} />
                                <label htmlFor="loan-radio_2">Business Loan</label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="radio">
                                <input type="radio" name="loan-radio" defaultValue="radio" id="loan-radio_1" value="personal loan" onChange={(e)=>{
                                  setLoanType("personal loan")
                                }} checked={loanType==="personal loan"} />
                                <label htmlFor="loan-radio_1">Personal Loan</label>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        <div className="form-group">
                            <input type="submit" className="btn btn-lg btn-block" value={"Submit"} disabled={submitting}/>
                        </div>
                      </form>
                    }
                    <div className="text-center tc-text">
                      <p>By submitting you agree to the Finstead Privacy Policy and Terms of Service.</p>
                    </div>
                  </div>
                </div>	
              </div>
            </div>
          </section>
     
          <section id="how-it-work" className="section-padding">
            <div className="container">
              <div className="section-header text-center">
                <h2>Work with us</h2>
                {/* <p>Get the financial help you need with the service you deserve. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="steps-wp">
                    <img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/icon-1.png" alt="image" className='colorised' />
                    <h4>Requirement</h4>
                    <p>Provide your details and we will work with you to identify the best product to meet your specific needs</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="steps-wp">
                    <img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/icon-2.png" alt="image" className='colorised'/>
                    <h4>Engagement</h4>
                    <p>Your personal finance professional will reach out to discuss the options available and get your loan approved</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="steps-wp">
                    <img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/icon-3.png" alt="image" className='colorised'/>
                    <h4>Settlement</h4>
                    <p>We manage the entire process from application to disbursement of funds so you can focus on your business</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="steps-wp">
                    <img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/icon-7.png" alt="image" className='colorised'/>
                    <h4>Commitment</h4>
                    <p>You have a trusted business partner that will assist you with all of your funding needs as your business grows</p>
                  </div>
                </div>
                
              </div>
            </div>
          </section>
          
  
          <section id="doc-eligibility" className="section-padding secondary-bg white-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="features-wrap border-right pr-5 text-sm-center text-md-left ">
                    <h2>Finstead </h2>
                    <ul>
                      <li><i className="fa fa-check-circle" /> Your own dedicated finance expert </li>
                      <li><i className="fa fa-check-circle" /> Market leading rates </li>
                      <li><i className="fa fa-check-circle" /> Easy application process</li>
                      <li><i className="fa fa-check-circle" /> Available when it suits you</li>
                      <li><i className="fa fa-check-circle" /> Multiple finance products</li>
                    </ul>
                    <a href="#" className="btn">Quick Apply</a>
                  </div>
                </div>
                <div className="col-md-6 text-center" style={{ minHeight: "200px", background: "url('https://cdn.finstead.com.au/images/process.jpeg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    
                </div>
              </div>
            </div>
          </section>
          
  
          {/* <section id="partners" className="section-padding">
            <div className="container">
              <div className="section-header text-center">
                <h2>Our Partners </h2>
                <p>Get the financial help you need with the service you deserve. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
              <div className="partners-logo">
                <ul>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                  <li><img src="https://cdn.finstead.com.au/capital-site-assets/assets/images/120x50.png" alt="image" /></li>
                </ul>
              </div>
            </div>
          </section> */}
          
  
          <section id="reviews" className="section-padding white-bg">
            <div className="container">
              <div className="section-header text-center black-text">
                <h2>What Our Customers are Saying</h2>
                <p>Get the financial help you need with the dedicated service you deserve.</p>
              </div>
              <OwlCarousel className="owl-theme" loop margin={10} autoplay animateIn={"fadeIn"} animateOut="fadeOut"  stagePadding={0} responsive={{
                  0: {
                    items: 1,
                    center: true,
                  },
                  700: {
                    items: 2,
                    center: true,
                  },
                  1000: {
                    items: 3,
                  },
                  1200: {
                    items: 4,
                  },
                }} dots={false}>
                  
                  <div className="item">
                  <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Dom.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>
                    <div className="user-info text-center mt-4">
                      <h5 className='mb-0'>Dominique</h5>
                      <p className='lead mb-1' style={{fontSize: "0.7em"}}>Renovation Excellence</p>
                    </div>
                    <p className='text-center' style={{fontSize: "0.8em"}}>I was very impressed with both the great customer service and attention to detail that I received from Lucinda at Finstead. She was charming and great to work with. She made the whole process very easy. I would recommend her to anyone.</p>
                  </div>
                  <div className="item">
                    <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Darren.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>

                    <div className="user-info text-center mt-4">
                      <h5 className='mb-0'>Darren</h5>
                      <p className='lead mb-1' style={{fontSize: "0.7em"}}>Tumbletown</p>
                    </div>
                    <p className='text-center' style={{fontSize: "0.8em"}}>{"Cant recommend Steve & the team from Finstead enough. They just get small business & the challenges it encompasses. Ive worked with Steve for over 5 years & have referred many other SME's to him. Hes my go to guy! Simple - No Fuss & Gets it done!"}</p>
                  </div>
                  <div className="item">
                  <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Luong.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>
                    <div className="user-info text-center mt-4">
                      <h5 className='mb-0'>Andrew</h5>
                      <p className='lead mb-1' style={{fontSize: "0.7em"}}>Saigon Bites</p>
                    </div>
                    <p className='text-center' style={{fontSize: "0.8em"}}>The team at finstead went above and beyond to get myself my first new car. From getting a competitive rate to sourcing the vehicle for me, everything was done within a couple of days and I couldn’t be more stoked with the car. You have a customer for life.</p>
                  </div>
                  <div className="item">
                    <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Olivia.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>
                    <div className="user-info text-center mt-4">
                      <h5 className='mb-0'>Olivia</h5>
                      <p className='lead mb-1' style={{fontSize: "0.7em"}}>Hitch Advisory</p>
                    </div>
                    <p className='text-center' style={{fontSize: "0.8em"}}>I highly recommend the Finstead team to all my clients who require asset finance. Their professional and genuine approach is refreshing and I feel confident they will get the best deals in the market. Definitely recommended.</p>
                  </div>
                  <div className="item">
                    <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Pramesh.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>
                    <div className="user-info text-center mt-4">
                      <h5 className='mb-0'>Pramesh</h5>
                      <p className='lead mb-1' style={{fontSize: "0.7em"}}>Clearstone Group</p>
                    </div>
                    <p className='text-center' style={{fontSize: "0.8em"}}>The guys at Finstead are the only place I would go or recommend friends or clients go for any type of business loan. They always deliver appropriate and speedy results. Steve is the man with all the answers for your business finance needs!”</p>
                  </div>
                </OwlCarousel>
                
            </div>
          </section>
  
{/*   
          <section id="faq" className="section-padding">
            <div className="container">
              <div className="section-header text-center">
                <h2>Frequently Asked Questions</h2>
                <p>Get the financial help you need with the service you deserve. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
              <div className="accordion mb-5" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> 1. At vero eos et accusamus et iusto odio dignissimos ducimus </button>
                    </h5>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 2. On the other hand, we denounce </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> 3. Which is the same as saying through shrinking </button>
                    </h5>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"> 4. On the other hand, we denounce </button>
                    </h5>
                  </div>
                  <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="card-body">
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </section> */}
        </div>
      </div>
    );

}

export default LanderPage;
import React, {useState, useEffect}  from 'react';
import axios from 'axios';
import { isPartnerSite } from 'App';
import Header from 'components/Header';

const TermsOfServicePage = () => {
    const [title, setTitle] = useState()
    const [configuration, setConfiguration] = useState({});

    useEffect(() => {
        if(isPartnerSite()){
            axios.get("https://prod-api.finstead.com.au/partners/lead-page-configuration/").then(response => {
            setConfiguration(response.data);
            setTitle(gettitle(partner))
            })
        }
    }, [])

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const gettitle = (_partner) => {

        if(Object.keys(configuration).indexOf("title") != -1) {
            return configuration.title
        }

        if(_partner.indexOf("and") !== -1){
            return _partner.split("and").map(x => capitalize(x)).join(" And ")
        }
        return capitalize(_partner)
    }

    const [partner, _] = useState(/:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0]);

    return (
        <>
            <Header undockedOverride={true} title={title} partner_config={configuration && Object.keys(configuration).length !== 0 ? configuration : undefined} />

            <div className='text-left container mt-4 pt-4'>
                <br />
                <h3>Terms of Service</h3>
                <p>Finstead Capital ACN 638 390 182 refers to the following Terms of Service, all applicable websites are referred to as the ‘Finstead Websites.' These Conditions of Use, together with any other terms, notices or disclaimers contained elsewhere on the Finstead Websites, govern the supply of material on, use of and access to the Finstead Websites. By accessing the Finstead Websites, you agree to be bound by these Conditions of Use and any modifications of them.</p>

                <h5>INFORMATION AND ACCURACY</h5>
                <p>Information provided on the Finstead websites:</p>
                <ul>
                    <li>is intended for Australian residents only and may not comply with non-Australian laws;</li>
                    <li>is of a general nature and does not take into account your individual circumstances, financial needs or objectives;</li>
                    <li>is not intended to be investment advice nor a recommendation or statement of opinion about any particular financial product or class of financial products. As a result, it should not be relied upon in acquiring any product or service offered by Finstead; and</li>
                    <li>is provided in good faith and derived from sources believed to be accurate and current at the date of publication.</li>
                </ul>
                
                <p>Finstead does not guarantee the accuracy, adequacy or completeness of information on the  Websites or that those websites are otherwise free from error, omission or other defect. Finstead reserves the right to change any information provided on Finstead Websites without notice.</p>

                <p>Unless otherwise specified, all references to currency are to Australian dollars.</p>

                <h5>YOUR CONDUCT</h5>
                <p>You warrant that when accessing and using the Finstead Websites you will not use any website for any purpose that is unlawful or prohibited by these On-line Conditions of Use or any other term, notice or disclaimer appearing on the Finstead Websites. </p>

                <p>You agree to not breach or circumvent, or attempt to breach or circumvent, any security measures on the Finstead Websites (including 'hacking' those websites) or attempt to obtain access to secure or member parts of the Finstead Websites without authorisation.</p>

                <p>Finstead retains the right to monitor your use of the Finstead Websites and prevent further access at any time in its discretion.</p>

                <h5>COPYRIGHT AND TRADE MARKS</h5>
                <p>All material on the Finstead Websites is protected by copyright. You may only use this material for your own personal reference. You must not otherwise use, reproduce, publish, modify, distribute, link, frame, transmit in any form or by any means, electronic or mechanical, for any purpose, any of the material on the Finstead Websites, except with the prior written permission of Finstead</p>

                <p>Trade marks used on the Finstead Websites are the property of Finstead or third parties with which Finstead has an association. You must not use a trade mark used on the Finstead Websites without the prior written consent of the owner of that trade mark.</p>

                <h5>LIMITATION OF LIABILITY</h5>
                <p>You agree that your use of the Finstead Websites and reliance on any information contained on those websites, is entirely at your own risk. To the extent permitted by law, Finstead excludes all liability for any loss or damage (including indirect or consequential loss or damage) you or any other person suffers in connection with your use of the Finstead Websites, including your reliance on any information contained on the Finstead Websites.</p>

                <h5>PRIVACY</h5>
                <p>Your personal information will be collected and used by Finstead in accordance with its Privacy Policy.</p>

                <h5>GOVERNING LAW</h5>
                <p>These On-line Conditions of Use and other terms, notices and disclaimers appearing on the Finstead Australia Websites are governed by the law in force in the state of Victoria. You agree to the non-exclusive jurisdiction of the courts of NSW, Australia and any courts which may hear appeals from those courts in respect of any proceedings in connection with these online conditions of us</p>
            </div>
        </>
    )
};

export default TermsOfServicePage;
import React, {useState, useEffect}  from 'react';
import axios from 'axios';
import { isPartnerSite } from 'App';
import Header from 'components/Header';

const PrivacyPolicyPage = () => {
    const [title, setTitle] = useState()
    const [configuration, setConfiguration] = useState({});

    useEffect(() => {
        if(isPartnerSite()){
            axios.get("https://prod-api.finstead.com.au/partners/lead-page-configuration/").then(response => {
            setConfiguration(response.data);
            setTitle(gettitle(partner))
            })
        }
    }, [])

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const gettitle = (_partner) => {

        if(Object.keys(configuration).indexOf("title") != -1) {
            return configuration.title
        }

        if(_partner.indexOf("and") !== -1){
            return _partner.split("and").map(x => capitalize(x)).join(" And ")
        }
        return capitalize(_partner)
    }

    const [partner, _] = useState(/:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0]);

    return (
        <>
            <Header undockedOverride={true} title={title} partner_config={configuration && Object.keys(configuration).length !== 0 ? configuration : undefined} />
            <div className='text-left container mt-4 pt-4'>
                <br />
                <h3>Privacy Policy</h3>
                <hr/>
                <h5>Who are we?</h5>
                <p>‘We’, ‘us’ and ‘our’ refer to Finstead Capital Pty Ltd ACN 638 390 182 and our subsidiaries and related businesses.</p>

                <h5>How we commit to your privacy</h5>
                <p>We understand how important it is to be careful with and protect your personal information. This document sets out our privacy policy commitment in respect of personal information we hold about you and what we do with that information.</p>

                <p>Our commitment in respect of personal information is to abide by the Australian Privacy Principles for the protection of personal information, as set out in the Privacy Act 1988 and any other relevant laws.</p>

                <p>We acknowledge that all personal information collected about you will only be used for the purposes we have collected it as allowed by law. It is important to us that you are confident that any personal information we hold about you will be treated in a way that protects your personal information.</p>

                <h5>Personal information</h5>
                <p>When we refer to personal information we mean information from which your identity is reasonably apparent. This information may include facts or an opinion about you.</p>

                <p>The kinds of personal information we may collect about you can include your name, date of birth, address, email address, phone number, account details, occupation, and any other information we made need to identify you.</p>

                <p>The personal information we collect and hold about you may include credit information. Credit information is information which is used to assess your eligibility to be provided with finance and may include any finance that you have outstanding, your repayment history in respect of those loans, and any defaults. Usually, credit information is exchanged between credit and finance providers and credit reporting bodies.</p>

                <p>If you are applying for finance we may also collect the ages and count of your dependants and cohabitants, the length of time you have spent at your current address, your employment details and proof of earnings and expenses.</p>

                <p>If you apply for employment with us we will collect information about your work history and ask your referees about you. If you want to make purchases from or through us we will also collect your payment information. We will also collect this information if we need to make payments to you. If you apply to become a Finstead referral partner we will collect information to enable us to ensure business can be concluded in a legal fashion.</p>

                <h5>How do we collect personal information?</h5>
                <p>We will seek to collect your personal information directly from you. We may also collect your personal information from credit reporting bodies, finance brokers and other people such as financial planners, vendors, accountants, lawyers and referees.</p>

                <h5>What do we do with your personal information?</h5>
                <p>We may disclose your personal information:</p>
                <ul>
                    <li>to prospective funders or other intermediaries in relation to your finance requirements;</li>

                    <li>to anyone, where you have provided us consent;</li>

                    <li>where we are required to do so by law, such as under the Anti-Money Laundering and Counter Terrorism Financing Act 2006 or in accordance with a subpoena or summons issued by a court;</li>

                    <li>to organisations that are involved in administering your finance such as third party suppliers, printing and postal services, call centres, lenders mortgage insurers, trade insurers and credit reporting bodies;</li>

                    <li>to associated businesses that want to market products to you;</li>

                    <li>to companies that provide information and infrastructure systems to us;</li>

                    <li>to anybody who represents you, such as finance brokers, lawyers, financial advisers and accountants;</li>

                    <li>to investors, agents, insurers or advisers, or any entity that has an interest in our business; or</li>

                    <li>to your employer, referees or identity verification services.</li>

                </ul>
                
                <p>Prior to disclosing any of your personal information to another person or organisation, we will take all reasonable steps to be sure that: the person or organisation has a commitment to protecting your personal information at least equal to our commitment, or you have provided us consent to make the disclosure.</p>

                <p>Where we use cloud storage to store personal information that we hold. The cloud storage and the IT servers may be located outside Australia.</p>

                <p>We may disclose your personal information to overseas entities that provide support functions to us. You may obtain more information about these entities by contacting us.</p>

                <h5>Why we need to collect personal information</h5>
                <p>We primarily collect personal information to allow us to assess and manage the process and ongoing management of your finance needs. We also collect your personal information for the purposes of direct marketing and managing our relationship with you and, on occasion, we may offer you various products and services.</p>

                <p>If you apply to become a referring partner with us or apply for employment with us we will collect information about you to assist us to decide whether to appoint you.</p>

                <p>We can collect payment information in order to process your payments.</p>

                <p>To enable us to maintain a successful business relationship with you, we may disclose your personal information to other organisations that provide products or services used or marketed by us. The types of organisations to which we are likely to disclose information about you include other finance intermediaries, our insurance partners, lenders, valuers, accountants, credit reporting bodies, recoveries firms, debt collectors and lawyers. We may also choose to disclose your personal information to any other organisation that may have or is considering having an interest in your finance, or in our business.</p>

                <h5>What type of Credit information we collect</h5>
                <p>We may collect the following kinds of credit information and exchange this information with credit reporting bodies and other entities:</p>

                <ul>

                    <li>credit liability information being information about your existing finance which includes the name of the credit provider, whether the credit provider holds an Australian Credit Licence, the type of finance, the day the finance is entered into, the terms and conditions of the finance, the maximum amount of finance available, and the day on which the finance was terminated;</li>
                    <li>repayment history information which is information about whether you meet your repayments on time;</li>
                    <li>information about the type of finance that you are applying for;</li>
                    <li>default and payment information; and</li>
                    <li>court proceedings information.</li>
                </ul>

                <p>We exchange this credit information for the purposes of assessing your application for finance and managing that finance.</p>
                <p>When we obtain credit information from a credit reporting body about you, we may also seek publicly available information and information about any serious credit infringement that you may have committed.</p>


                <h5>Direct marketing</h5>

                <p>From time to time we may use your personal information to provide you with current information about finance offers you may find of interest, changes to our organisation, or new products or services being offered by us or any company with whom we are associated.</p>

                <p>If you do not wish to receive marketing information, you may at any time decline to receive such information by emailing us at marketing@afgonline.com.au. If the direct marketing is by email you may also use the unsubscribe function. We will not charge you for giving effect to your request and will take all reasonable steps to meet your request within a reasonable timeframe.</p>


                <h5>Updating your personal information</h5>

                <p>It is important to us that the personal information we hold about you is accurate and up to date. During the course of our relationship with you we may ask you to inform us if any of your personal information has changed.</p>

                <p>If you wish to make any changes to your personal information, you may contact us. We will generally rely on you to ensure the information we hold about you is accurate and complete.</p>

                <h5>Access and correction to your personal and credit information</h5>
                
                <p>We will provide you with access to the personal and credit information we hold about you. You may request access to any of the personal information we hold about you at any time.</p>

                <p>We may charge a fee for our costs of retrieving and supplying the information to you.</p>

                <p>We aim to respond within 30 days of receiving your request. We may need to contact other entities to properly investigate your request.</p>

                <p>There may be situations where we are not required to provide you with access to your personal information, for example, if the information relates to existing or anticipated legal proceedings, or if your request is vexatious.</p>

                <p>An explanation will be provided to you if we deny you access to the personal information we hold about you.</p>

                <p>If any of the personal information we hold about you is incorrect, inaccurate or out of date you may request that we correct the information. If appropriate we will correct the personal information. We aim to provide you with details about whether we have corrected the personal information within 30 days of receiving your request.</p>

                <p>We may need to consult with other entities as part of our investigation.</p>

                <p>If we refuse to correct personal information we will provide you with our reasons for not correcting the information.</p>

                <h5>What is a Notifiable matter</h5>
                <p>The law requires us to advise you of ‘notifiable matters’ in relation to how we may use your credit information. You may request to have these notifiable matters (and this privacy policy) provided to you in an alternative form.</p>

                <p>We exchange your credit information with credit reporting bodies. We use the credit information that we exchange with the credit reporting body to assess your creditworthiness, assess your application for finance and manage your finance.</p>

                <p>If you fail to meet your payment obligations in relation to any finance that we have provided or arranged or you have committed a serious credit infringement then we may disclose this information to a credit reporting body.</p>

                <p>You have the right to request access to the credit information that we hold about you and make a request for us to correct that credit information if needed. Please see the heading Access and correction to your personal and credit information below.</p>

                <p>Sometimes your credit information will be used by credit reporting bodies for the purposes of ‘pre-screening’ credit offers on the request of other credit providers. You can contact the credit reporting body at any time to request that your credit information is not used in this way.</p>

                <p>You may contact the credit reporting body to advise them that you believe that you may have been a victim of fraud. For a period of 21 days after the credit reporting body receives your notification the credit reporting body must not use or disclose that credit information. You can contact any of the following credit reporting bodies for more information:</p>

                <ul>
                    <li>equifax.com.au,</li>
                    <li>dnb.com.au, or</li>
                    <li>experian.com.au.</li>
                </ul>

                <h4>Using government identifiers</h4>
                <p>When we collect government identifiers, such as your tax file number, we do not use or disclose this information other than as required by law. We will never use a government identifier to identify you.</p>

                <h4>Business without identifying you</h4>
                <p>In most circumstances it will be necessary for us to identify you in order to successfully do business with you, however, where it is lawful and practicable to do so, we will offer you the opportunity of doing business with us without providing us with personal information, for example, if you make general inquiries about interest rates or current promotional offers.</p>

                <h4>Sensitive information</h4>
                <p>We will only collect sensitive information about you with your consent. Sensitive information is personal information that includes information relating to your racial or ethnic origin, political persuasion, memberships in trade or professional associations or trade unions, sexual preferences, criminal record, or health.</p>

                <h4>How safe and secure is your personal information that we hold?</h4>
                <p>We will take reasonable steps to protect your personal information by storing it in a secure environment. We may store your personal information in paper and electronic form. We will also take reasonable steps to protect any personal information from misuse, loss and unauthorised access, modification or disclosure.</p>

                <h4>Complaints</h4>
                <p>If you are dissatisfied with how we have dealt with your personal information, or you have a complaint about our compliance with the Privacy Act, you may contact our complaints officer by email at privacy@finstead.com.au.</p>

                <p>We will seek to acknowledge your complaint within seven days and make a decision on your complaint within 30 days.</p>

                <p>If this does does remedy your complaint you are able to contact our External Dispute Resolution Scheme, the Credit and Investments Ombudsman (CIO) Australian Financial Complaints Authority (AFCA) which can be contacted on either www.afca.org.au or 1800 931 678; or the Office of the Australian Information Commissioner (OAIC) which can be contacted on either www.oaic.gov.au or 1300 363 992.</p>

                <h5>Further information</h5>
                <p>You may request further information about the way we manage your personal or credit information by contacting us at privacy@finstead.com.au.</p>

                <h5>Change in our privacy policy</h5>
                <p>We seek to remain relevant and up to date in all of our policies on privacy, this may mean this policy changes from time to time.</p>

                <p>You may request this privacy policy in a different format.</p>

                <p>This Privacy Policy was last updated on 1 June 2020.</p>
            </div>
        </>
    )
}

export default PrivacyPolicyPage;
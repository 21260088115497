import React from 'react';
import {useHistory} from 'react-router-dom';

const Footer = () => {
    const history = useHistory();

    return (
        <footer id="footer" className="white-text secondary-bg">
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="contact-email">
                      <img src="https://cdn.finstead.com.au/finstead-logo-transparent.png" alt="logo" style={{maxHeight: "30px"}}/>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="follow-us">
                      <ul>
                        <li onClick={() => {
                          window.scrollTo(0, 0)
                          history.push("/privacy-policy")
                        }} className='clickable'>Privacy Policy</li>
                        <li onClick={() => {
                          window.scrollTo(0, 0)
                          history.push("/terms-of-service")
                        }} className='clickable'>Terms of Service</li>
                        <li onClick={() => {
                          window.scrollTo(0, 0)
                          history.push("/contact")
                        }} className='clickable'>Contact Us</li>
                      </ul> 
                    </div>
                  </div>
                </div>
              </div>	
            </div>
            <div className="footer-bottom text-center">
              <div className="container">
                <p>Copyright © Finstead Capital PTY LTD</p>    
              </div>	
            </div>
        </footer>
    )
}

export default Footer;
import React, {useState ,useEffect} from 'react';
import { Helmet } from 'react-helmet'
import './styles.css';

const Header = ({title, partner_config, undockedOverride}) => {
    const [headerTitle, setTitle] = useState((title ? `${title} | Finstead` : title) || "Finstead");

    let listener = null;
    const [docked, setDocked] = useState(false);  //useState(undockedOverride ? false : true);

    const updateDockStatus = () => {
      var scrolled = document.scrollingElement.scrollTop
        if (scrolled >= 60) {
          if(docked){
              //setDocked(undockedOverride ? false : false);
          }
        } else {
          if(!docked){
              //setDocked(undockedOverride ? false : true)
          }
        }
    }

    useEffect(updateDockStatus, [])

    useEffect(() => {
        listener = document.addEventListener("scroll", e => {
          updateDockStatus();
        })
        return () => {
          document.removeEventListener("scroll", listener)
        }
      }, [docked])

    return (
        <>
            {/* Header */}
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>

            {/* Navigation */}
            <nav id="finstead-nav" className={`animate navbar sticky-top text-left bg-clear ${docked ? 'bg-clear text-white' : 'bg-white text-black'}`}>
                <div className='container'>
                <div className="navbar-header" >

                {/* Preload Logo */}
                
                <img src={`https://cdn.finstead.com.au/logos/finstead-light-transparent.png`} alt={'Finstead Capital'} style={{display: "none"}} />
                <img src={`https://cdn.finstead.com.au/logos/finstead-dark-transparent.png`} alt={'Finstead Capital'} style={{display: "none"}} />
                
                
                {/* <div className="logo">  */}
                    {
                      partner_config !== undefined ?
                        <>
                        <a href="https://finstead.com.au/"><img src={docked ? partner_config.logo_url_light : partner_config.logo_url_dark} alt={partner_config.title} 
                          style={{
                            paddingTop: "5px", paddingBottom: "5px", maxHeight: docked ? "35px" : partner_config ? "35px" : "35px", width: "auto",
                            ...(docked ? partner_config.logo_url_light_config : partner_config.logo_url_dark_config)
                          }} /></a>

                        <div style={{paddingLeft: "15px", marginLeft: "15px", borderLeft: "1px solid #e6e6e6", display: "inline-block"}}>
                          <small style={{display: "block", fontSize: "0.7em", lineHeight: "10px", ...partner_config.logo_url_light_config}} className={docked ? 'white-text' : 'black-text'}>Powered By</small>
                          <a href={partner_config.website}><img src={`https://cdn.finstead.com.au/logos/finstead-${docked ? 'light' : 'dark'}-transparent.png`} alt={'Finstead Capital'} style={{maxHeight: docked ? "17px" : "15px", width: "auto"}} /></a>
                        </div>
                        
                        </>
                      :
                      <a href="https://finstead.com.au/"><img src={`https://cdn.finstead.com.au/logos/finstead-${docked ? 'light' : 'dark'}-transparent.png`} alt="Finstead" style={{maxHeight: docked ? "35px" : partner_config ? "25px" : "30px", width: "auto"}} /></a>
                    }
                {/* </div> */}
                {/* /Logo */}
                <a href="tel:+611300106029" className={`menu-slide ${docked ? "" : "undocked"}`}><i className="fa fa-phone" style={{marginLeft: "11px"}} /></a>
                </div>
                <div className="text-right" id="navigation" style={{margin: "auto none"}}>
                {/* <ul className="navbar-nav">
                    <li><a href="#how-it-work" className="js-target-scroll" style={{color: "white"}}>{/*How it works</a></li>
                    <li><a href="#doc-eligibility" className="js-target-scroll" style={{color: "white"}}>{/*Documents &amp; Eligibility</a></li>
                    <li><a href="#faq" className="js-target-scroll" style={{color: "white"}}>{/*FAQ</a></li>
                </ul> */}
                <a className={`call-us-action ${docked ? '' : 'undocked'}`} style={{marginTop: "0px"}} href="tel:+611300106029"><small><strong><i className='fa fa-phone mr-2'></i>1300 106 029</strong></small></a>
                
                {/* <a className={`partners-action ml-2 ${docked ? '' : 'undocked'} clickable`} ><small><strong><i className='fa fa-user mr-2'></i>Partners</strong></small></a> */}
                </div>
                </div>
            </nav>
            
        </>
    )
}

export default Header;
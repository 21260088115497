import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './styles.css'; 

const HomePage = () => {
  return (
    <>
      <section id="intro-2" className="section-padding no-padding-bottom">
        <div className="container">
          <div className="intro-text white-text z-index text-center" style={{paddingTop: "100px", paddingBottom: "100px", minHeight: "400px"}}>
            <h1><span className='partners-highlight'>YOUR PARTNERS</span> IN BUSINESS FINANCE</h1>
            <p className="lead">
              {
                "Working with small to medium businesses seeking finance or capital Easy to Apply & Fast Approval"
              }
            </p>
            <div className="text-center text-white lead">
              <u>
                <a
                  href="tel:1300 106 029"
                  target=""
                  className="text-white text-underline"
                >
                  <i className='fa fa-phone mr-2'></i>Call 1300 106 029
                </a>
              </u>
              <br />
              <div className='mt-4' style={{backgroundColor: "#D9963D", padding: "5px", margin: "auto auto", maxWidth: "150px", borderRadius: "40px", cursor: "pointer"}} onClick={() => {window.location.href = "https://finstead.com.au/contact"}}>
              <a
                  href="https://finstead.com.au/contact"
                  className="text-white"
                >
                  Do I Qualify?
              </a>
              </div>
            </div>

          </div>
        </div>
        <div className='w-100 hero-seperator'>
          
        </div>
      </section>

      <section id="about-us" className="section-padding">
        <div className="container text-center">
          <div className="section-header text-center">
            <div className="section-title">Welcome to Finstead</div>
            <i className='fa fa-hands-helping' />
          </div>
          <p>
            {
              "Whether you are a start up or a well established listed business, \
                        we are committed to listening to your needs and working with you for a fast outcome. \
                        Our people are experienced finance and insurance professionals who understand business \
                        and more importantly understand the Australian credit landscape."
            }
          </p>
          <p>
            {
              "Finstead Capital was \
              founded because we could see that businesses weren’t being given the right level of \
              support and service to access the best available finance products in market. \
              Often at the expense of the financial health of the business or at the expense of business \
              opportunities. We were convinced that there’s a better, faster, simpler way and a more \
              sustainable model to finance the needs of business. Whilst not losing sight of the \
              importance of our people, our integrity, the brand and the needs of our partners."
            }
          </p>
          
        </div>
      </section>

      <section id='testimonial' className='section-padding testimonial-banner'>
        <div className='w-100 mb-3'>
            <div className='container'>
              <div className='w-100 text-center'>
                <h3 className='section-title text-white'>What our clients say</h3>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-4'>
                  <div className="customer-img" style={{height: "200px", margin: "auto auto", width: "200px", background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Olivia.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", borderRadius: "150px"}}/>
                </div>
                <div className='col-xs-12 col-sm-8 text-left pt-4'>
                  <p><span style={{fontSize: "2em"}}>" </span>I highly recommend the Finstead team to all my clients who require asset finance. Their professional and genuine approach is refreshing and I feel confident they will get the best deals in the market. Definitely recommended.<span style={{fontSize: "2em"}}> "</span></p>
                  <small>Oliva - Hitch Advisory</small>
                </div>
              </div>
            </div>
        </div>
      </section>

      <section id="services" className="section-padding">
        <div className="container">
          <div className="section-header text-center">
            <div className="section-title">Our Services</div>
            <p>{""}</p>
          </div>
          <div id="our-services">
            <OwlCarousel className="owl-theme" loop margin={10} autoplay responsive={{
              0: {
                items: 1,
                center: true,
              },
              700: {
                items: 2,
                center: true,
              },
              1000: {
                items: 3,
              },
              1200: {
                items: 4,
              },
            }} dots={false}>

                <div className="text-center" >
                    <div style={{maxWidth: "265px", margin: "auto auto"}}>
                      <div style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/commercial-property.jpg')"}} className="service-icon mb-4">
                      
                      </div>
                      <h5>Commercial Property</h5>
                      <p>
                      Whether it’s buying, refinancing, releasing equity or a bridging solution. We have over 40 specialist lenders we work with to attain the best and fastest outcome. From private equity to top tier banks.
                      </p>
                    </div>
                </div>

                <div className="text-center" >
                  <div style={{maxWidth: "265px", margin: "auto auto"}}>
                    <div style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/working-capital.jpeg')"}} className="service-icon mb-4" >
                  
                    </div>
                    <h5>Working Capital</h5>
                    <p>
                    Debtor finance, supply chain solutions or just general purpose funds for day to day to running of the business. We have the latest solutions from bank and non bank lenders, with over 36 solutions.
                    </p>
                    </div>
                </div>

                <div className="text-center" >
                  <div style={{maxWidth: "265px", margin: "auto auto"}}>
                    <div style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/asset-finance.jpg')"}} className="service-icon mb-4">
                    
                    </div>
                    <h5>Asset Finance</h5>
                    <p>
                      We have the most experienced team of asset finance advisors in Australia, from yellow equipment, cars, trucks, boats and even aircraft. Leverage our buying power and credibility, with over 60 lenders.
                    </p>
                    </div>
                </div>

                <div  className="text-center" >
                  <div style={{width: "265px", margin: "auto auto"}}>
                    <div className="service-icon mb-4" style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/business-acquisition.jpg')"}}>
                    
                    </div>
                    <h5>Business Acquisition</h5>
                    <p>
                      We can assist with valuing, structuring and negotiating the purchase. We have a range of well priced solutions. With a team who are well versed in the science of mergers and acquisitions.
                    </p>
                  </div>
                </div>

                <div  className="text-center" >
                  <div style={{width: "265px", margin: "auto auto"}}>
                    <div className="service-icon mb-4" style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/trade-finance.jpeg')"}}>
                    
                    </div>
                    <h5>Trade Finance</h5>
                    <p>
                      Fast and simple solutions from Australia’s most innovating trade finance lenders. If you’re importing consumables, technical equipment or even large mining equipment, we’ve seen it and have delivered great outcomes.
                    </p>
                  </div>
                </div>

                <div className="text-center" >
                  <div style={{width: "265px", margin: "auto auto"}}>
                    <div className="service-icon mb-4" style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/refinance.jpeg')"}} >
                    
                    </div>
                    <h5>Refinance</h5>
                    <p>
                      Bank and non bank lenders have tightened credit, we have a range of capital sources to refinance term loans coming to their conclusion. Whether a business loan, asset finance or commercial property.
                    </p>
                  </div>
                </div>

                <div className="text-center" >
                  <div style={{width: "265px", margin: "auto auto"}}>
                    <div className="service-icon mb-4" style={{background: "url('https://cdn.finstead.com.au/capital-site-assets/assets/images/insurance.jpg')"}}>
                    
                    </div>
                    <h5>Insurance</h5>
                    <p>
                    We have the most innovative General Business Insurance products at a highly discounted rate, from some of the worlds best known brands. Ask for a second opinion, it won’t cost you anything.
                    </p>
                  </div>
                </div>
                
             </OwlCarousel>
            <div className='text-center'>
             <h4>Ready to start?</h4>
            <div className='mt-4' style={{backgroundColor: "#D9963D", padding: "5px", margin: "auto auto", maxWidth: "250px", borderRadius: "40px", cursor: "pointer"}} onClick={() => {window.location.href = "https://finstead.com.au/contact"}}>
              <a
                  href="https://finstead.com.au/contact"
                  className="text-white"
                >
                  Get Pre-Approved Today
              </a>
              </div>
              </div>
          </div>
        </div>
      </section>

      

      

      <section className="section-padding bg-dark">
        <div className="container">
          <div className="section-header text-center">
            <div className="section-title">How we are different</div>
            <i className='fa fa-road' />
          </div>
          <div className="white-text text-center">
            <p>
              {
                "The Finstead difference is that we’re small business owners and have walked in your shoes."
              }
            </p>
            <p>{"We’re here to do the heavy lifting and carry the load."}</p>
          </div>
        </div>
      </section>

      
    </>
  );
};

export default HomePage;
